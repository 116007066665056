<template>
  <div class="home">
    <MiscellanFrom msg="Welcome to Your MiscellanFrom App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import MiscellanFrom from '@/components/MiscellanFrom.vue'
  export default {
    name: 'Miscellan',
    props: {
      //
    },
    components: {
      MiscellanFrom
    },
    data: () => ({
      //
    }),
    methods: {
      initialize: function () {
        //
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
